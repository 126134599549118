import { child } from "@firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { DataBase } from "../database/database";
import { DataSnapshot } from "@firebase/database";
import { get } from "@firebase/database";
import { getAuth } from "firebase/auth";
import { getDatabase } from "@firebase/database";
import { getToken } from "../firebaseConfig.js";
import { ref } from "@firebase/database";
import { sendEmailVerification } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { User } from "firebase/auth";
import { Usuario } from "../models/usuario";
import { Modal } from "antd";

export class APIUsuario {
  async create(
    email: string,
    password: string,
    nombre: string,
    perfil: string,
    sede: string
  ): Promise<any> {
    let user: User;
    let response: {} = {};
    const auth = getAuth();
    const database = new DataBase();
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        user = userCredential.user;
        this.update(user, nombre);
        database.set("usuarios/", user.uid, {
          perfil: perfil,
          nombre: nombre,
          email: email,
          sede: sede ?? "",
        });
        response = { success: true, data: user };
      })
      .catch((error) => {
        response = { success: false, data: error };
      });
    return response;
  }

  async update(user: User, nombre: string): Promise<any> {
    updateProfile(user, {
      displayName: nombre,
    })
      .then(() => {})
      .catch((error) => {
        Modal.error({
          title: "3AAMSEQ",
          content: error,
        });
      });
  }

  async verification(user: User): Promise<any> {
    sendEmailVerification(user).then(() => {});
  }

  async login(email: string, password: string): Promise<any> {
    const auth = getAuth();
    let response: {} = {};
    let usuario: Usuario = new Usuario({});
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const db = getDatabase();
        usuario.nombre = userCredential.user.displayName ?? "";
        let dbRef: any = ref(db);
        await get(child(dbRef, `usuarios/${userCredential.user.uid}`))
          .then((snapshot: DataSnapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              usuario.id = userCredential.user.uid;
              usuario.perfil = data.perfil;
              usuario.sede = data.sede;
            }
          })
          .catch((error) => {
            response = { success: false, data: error };
          });
        if (usuario.perfil === "Administrador" || usuario.perfil === "Ventas") {
          const database = new DataBase();
          getToken().then((_token: string) =>
            database.set("tokens/", userCredential.user.uid, {
              usuario: userCredential.user.uid,
              token: _token,
              perfil: usuario.perfil,
              fechaCreacion: new Date().toLocaleString(),
            })
          );
          this.saveToken(usuario);
          response = {
            success: true,
            data: usuario,
          };
        } else {
          response = {
            success: false,
            data: {
              message: "Sólo pueden ingresar los administradores",
            },
          };
        }
      })
      .catch((error) => {
        response = { success: false, data: error };
      });
    return response;
  }

  async getCurrentUser(): Promise<Usuario> {
    const str = localStorage.getItem("TOKEN_DATA_KEY");
    if (!str) {
      return new Usuario({});
    }
    const data = JSON.parse(str);
    const db = getDatabase();
    let dbRef: any = ref(db);
    await get(child(dbRef, `usuarios/${data.id}`))
      .then((snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          const _data = snapshot.val();
          data.perfil = _data.perfil;
          data.sede = _data.sede;
        }
      })
      .catch((error) => {
        Modal.error({
          title: "3AAMSEQ",
          content: error,
        });
      });
    if (!data) {
      return new Usuario({});
    }
    return data as any;
  }

  async deleteTokenFCM(usuario: string): Promise<any> {
    const database = new DataBase();
    database.remove("tokens", usuario);
  }

  private saveToken(data: any) {
    localStorage.setItem("TOKEN_DATA_KEY", JSON.stringify(data));
  }
}
