import { Producto } from "./producto";

export interface SolicitudDataI {
  id?: String;
  correlativo?: String;
  codigo?: String;
  version?: number;
  administradorTienda?: String;
  fechaReporte?: String;
  fechaApertura?: String;
  fechaCierre?: String;
  responsableAlmacen?: String;
  tienda?: String;
  productos?: Array<Producto>;
  estado?: String;
}

export class Solicitud implements SolicitudDataI {
  id?: String;
  correlativo?: String;
  codigo?: String;
  version?: number;
  administradorTienda?: String;
  fechaReporte?: String;
  fechaApertura?: String;
  fechaCierre?: String;
  responsableAlmacen?: String;
  tienda?: String;
  productos?: Array<Producto>;
  estado?: String;
  constructor(data: SolicitudDataI) {
    this.id = data.id;
    this.correlativo = data.correlativo;
    this.codigo = data.codigo;
    this.version = data.version;
    this.administradorTienda = data.administradorTienda;
    this.fechaReporte = data.fechaReporte;
    this.fechaApertura = data.fechaApertura;
    this.fechaCierre = data.fechaCierre;
    this.responsableAlmacen = data.responsableAlmacen;
    this.tienda = data.tienda;
    this.productos = data.productos;
    this.estado = data.estado;
  }

  toMap() {
    return {
      id: this.id ?? "",
      correlativo: this.correlativo ?? "",
      codigo: this.codigo ?? "",
      version: this.version ?? "",
      administradorTienda: this.administradorTienda ?? "",
      fechaReporte: this.fechaReporte ?? "",
      fechaApertura: this.fechaApertura ?? "",
      fechaCierre: this.fechaCierre ?? "",
      responsableAlmacen: this.responsableAlmacen ?? "",
      tienda: this.tienda ?? "",
      productos: this.productos ?? "",
      estado: this.estado ?? "",
    };
  }

  fromMap(data: any) {
    this.id = data["id"];
    this.correlativo = data["correlativo"];
    this.codigo = data["codigo"];
    this.version = data["version"];
    this.administradorTienda = data["administradorTienda"];
    this.fechaReporte = data["fechaReporte"];
    this.fechaApertura = data["fechaApertura"];
    this.fechaCierre = data["fechaCierre"];
    this.responsableAlmacen = data["responsableAlmacen"];
    this.tienda = data["tienda"];
    this.productos = data["productos"];
    this.estado = data["estado"];
    return this;
  }
}
