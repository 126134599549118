import "../../../styles/pages/dashboard/index.css";
import { APIUsuario } from "../../../api/api_usuario";
import { ArrowDownOutlined } from "@ant-design/icons";
import { BarChartOutlined } from "@ant-design/icons";
import { Col, Spin } from "antd";
import { Dropdown } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { Menu } from "antd";
import { Modal } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { Row } from "antd";
import { Switch } from "react-router-dom";
import { Fragment, useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../../models/usuario";
import logoBlanco from "../../../assets/logoBlanco.svg";
import ProductosPage from "./productos.page";
import SolicitudesPage from "./solicitudes.page";
import UsuarioContext from "../../../providers/usuarios-provider";
import ReportePage from "./reporte.page";

function DashboardPage() {
  const [isOnline, setIsOnline] = useState<any>(null);
  const apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Header, Content, Sider } = Layout;
  const history = useHistory();
  const location = useLocation();
  const [usuario, setUsuario] = useState<Usuario>(new Usuario({}));
  const [visible, setVisible] = useState<boolean>(true);
  const [path, setPath] = useState("");

  const getCurrentUser = useCallback(async () => {
    let result = await apiUsuario.getCurrentUser();
    if (result) {
      let user: Usuario = new Usuario(result);
      setUsuario(user);
      if (!user.id) {
        history.replace("/login");
      }
    }
    setIsOnline(true);
  }, [apiUsuario, history]);

  useEffect(() => {
    setIsOnline(true);
    window.onunload = function () {
      apiUsuario.deleteTokenFCM(usuario.id ?? "");
      localStorage.clear();
    };

    window.onbeforeunload = function () {
      return "Si recargas la página, deberás volver a iniciar sesión";
    }

    getCurrentUser();
    const data = location.pathname.split("/");
    if (data && data.length > 0) {
      const path = data[data.length - 1];
      setPath(path);
    }
    return () => { setIsOnline(false) }
  }, [location, apiUsuario, history, getCurrentUser, usuario]);

  const singOut = (_: any) => {
    Modal.confirm({
      title: "Confirmación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Está seguro de cerrar su sesión?",
      okText: "Si, cerrar",
      cancelText: "No, cancelar",
      onOk: () => {
        apiUsuario.deleteTokenFCM(usuario.id ?? "");
        localStorage.clear();
        history.replace("/login");
      },
    });
  };

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }

  return isOnline ? (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => { }}
        onCollapse={(collapsed, type) => {
          if (collapsed === false) {
            setVisible(false);
          } else {
            setVisible(true);
          }
        }}
      >
        <div className="logo">
          <img src={logoBlanco} alt="3AAMSEQ" title="3AAMSEQ" />
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[path]}>
          {usuario.perfil === "Administrador" ? (
            <Fragment>
              <Menu.Item
                key="solicitudes"
                icon={<FileAddOutlined />}
                onClick={() => {
                  history.push("/solicitudes");
                }}
              >
                Solicitudes
              </Menu.Item>
              <Menu.Item
                key="productos"
                icon={<BarChartOutlined />}
                onClick={() => {
                  history.push("/productos");
                }}
              >
                Productos
              </Menu.Item>
            </Fragment>
          ) : (
            <Fragment>
              <Menu.Item
                key="reporte"
                icon={<FileAddOutlined />}
                onClick={() => {
                  history.push("/reporte");
                }}
              >
                Reporte
              </Menu.Item>
            </Fragment>
          )}
        </Menu>
      </Sider>

      <Layout className="site-layout">
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0 }}
        >
          <Row>
            <Col
              xs={visible ? 24 : 0}
              sm={visible ? 24 : 0}
              md={visible ? 24 : 0}
              lg={visible ? 24 : 0}
              xl={visible ? 24 : 0}
              xxl={visible ? 24 : 0}
              className="right"
            >
              <Dropdown.Button
                overlay={
                  <Menu onClick={singOut}>
                    <Menu.Item key="1" icon={<PoweroffOutlined />}>
                      Cerrar sesi&oacute;n
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomCenter"
                icon={<ArrowDownOutlined />}
                trigger={["click"]}
              >
                {usuario.nombre ?? "Usuario logeado"}
              </Dropdown.Button>
            </Col>
          </Row>
        </Header>

        <Content>
          <Layout className="site-layout-background">
            {usuario.perfil === "Administrador" ? (
              <Switch>
                <Route path={`/productos`}>
                  <ProductosPage usuario={usuario} />
                </Route>
                <Route path={`/solicitudes`}>
                  <SolicitudesPage usuario={usuario} />
                </Route>
                <Redirect to="/productos"></Redirect>
              </Switch>
            ) : (
              <Switch>
                <Route path={`/reporte`}>
                  <ReportePage usuario={usuario} />
                </Route>
                <Redirect to="/reporte"></Redirect>
              </Switch>
            )}
          </Layout>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <Fragment>
      <h1>Hubo un error, consulte con TI</h1>
    </Fragment>
  );
}
export default DashboardPage;
