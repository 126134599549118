import Carousel from "react-elastic-carousel";
import { Image } from "antd";
import Global from "../../global/global";
function VerFotos({ imagesProductos }: { imagesProductos: Array<String> }) {
  return (
    <Carousel itemsToShow={1} isRTL={false} showArrows={false}>
      {imagesProductos.length > 0 ? (
        imagesProductos.map((imagen, index) => (
          <div key={index}>
            <Image
              key={index}
              width={"95%"}
              height={300}
              src={imagen as string}
              fallback={Global.IMAGE_ERROR}
              style={{ borderRadius: "20px" }}
            />
          </div>
        ))
      ) : (
        <div></div>
      )}
    </Carousel>
  );
}

export default VerFotos;
