import "../../../styles/pages/dashboard/productos.css";
import { APIProducto } from "../../../api/api_producto";
import { CloseCircleFilled } from "@ant-design/icons";
import { Col, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { getDatabase } from "@firebase/database";
import { Modal } from "antd";
import { onValue } from "@firebase/database";
import { Producto } from "../../../models/producto";
import { ref } from "@firebase/database";
import { Row } from "antd";
import { Services } from "../../../services/services";
import { Space } from "antd";
import { Tooltip } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../../models/usuario";
import ListaProductos from "../../components/lista_productos";
import ProductoContext from "../../../providers/productos-provider";
import ServicesContext from "../../../providers/servicios-provider";
import VerFotos from "../../components/ver_fotos";
import { Solicitud } from "../../../models/solicitud";

function ProductosPage({ usuario }: { usuario: Usuario }) {
  const [isOnline, setIsOnline] = useState<any>(null);
  const apiProducto = useContext<APIProducto>(ProductoContext);
  const apiServicios = useContext<Services>(ServicesContext);
  const [imagesProductos, setImagesProductos] = useState<Array<String>>([]);
  const [visibleFotos, setVisibleFotos] = useState<boolean>(false);
  const [productos, setProductos] = useState<Array<Producto>>([]);

  const [administradorTienda, setAdministradorTienda] = useState<String>("");
  const { Title } = Typography;

  const getProductos = useCallback(async () => {
    setProductos([]);
    const db = getDatabase();
    let dbRef: any = ref(db, `productos/${usuario.sede}`);
    let _productos: Array<Producto> = [];
    let producto: Producto = new Producto({});
    onValue(dbRef, (snapshot: any) => {
      _productos = [];
      snapshot.forEach((doc: any) => {
        const id = doc.ref._path.pieces_[2];
        producto = new Producto(doc.val());
        producto.id = id;
        if (producto.suscrito === false) {
          _productos.push(producto);
        }
      });
      setProductos(
        _productos.sort(
          (a: Producto, b: Producto) =>
            b.fecha?.localeCompare(a.fecha as string) ?? 0
        )
      );
    });
    setIsOnline(true);
  }, [usuario]);

  const acciones = (producto: Producto) => {
    return (
      <Space key={producto.id?.toString()}>
        <Tooltip title="Eliminar">
          <CloseCircleFilled
            style={{ color: "#f44336", fontSize: "x-large" }}
            onClick={() => deleteProducto(producto)}
          />
        </Tooltip>
      </Space>
    );
  };

  const renderMotivo = (producto: Producto) => {
    if (producto.submotivo) {
      return producto.motivo + ": " + producto.submotivo;
    } else {
      return producto.motivo;
    }
  };

  const deleteProducto = async (producto: Producto) => {
    Modal.confirm({
      title: "Confirmación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Está seguro de eliminar el producto?",
      okText: "Si, eliminar",
      cancelText: "No, cancelar",
      onOk: async () => {
        await apiProducto
          .deleteProducto(producto)
          .then((success) =>
            Modal.success({
              title: "3AAMSEQ",
              content: "Producto eliminado",
            })
          )
          .catch((error) =>
            Modal.error({
              title: "3AAMSEQ",
              content: "Error al eliminar producto: " + error,
            })
          );
      },
    });
  };

  const verFotos = (images: Array<String>) => {
    setImagesProductos(images);
    setVisibleFotos(true);
  };

  const onCancelFotos = () => {
    setImagesProductos([]);
    setVisibleFotos(false);
  };

  const getAdministrador = useCallback(async () => {
    let administradorTienda: String = await apiServicios.getAdministrador(
      usuario
    );
    setAdministradorTienda(administradorTienda);
    setIsOnline(true);
  }, [apiServicios, usuario]);

  useEffect(() => {
    getProductos();
    getAdministrador();
    return () => {
      setIsOnline(false)
    }
  }, []);

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }

  return isOnline ? (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={2} className="azulC tituloProductoProducto">
            Lista de productos fallados
          </Title>
          <Title level={5} className="azulC subtituloProductoProducto">
            A continuación se muestra el listado de las productos registrados
            como fallados
          </Title>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="colTablaProductos"
        >
          <ListaProductos
            acciones={acciones}
            productos={productos}
            verFotos={verFotos}
            renderMotivo={renderMotivo}
            usuario={usuario}
            administradorTienda={administradorTienda}
            origen="PRODUCTOS"
            solicitud={new Solicitud({})}
          />
        </Col>
      </Row>
      {/* Modal para ver las fotos de los productos */}
      <Modal
        maskClosable={false}
        style={{ textAlign: "center" }}
        title="Fotos del producto"
        visible={visibleFotos}
        onCancel={onCancelFotos}
        footer={null}
      >
        <VerFotos imagesProductos={imagesProductos} />
      </Modal>
    </Fragment>
  ) :
    (
      <Fragment>
        <h1>Hubo un error, consulte con TI</h1>
      </Fragment>
    );
}

export default ProductosPage;
