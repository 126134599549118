import { BrowserRouter as Router } from "react-router-dom";
import { Fragment, useState } from "react";
import { onMessageListener } from "../../firebaseConfig.js";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { useEffect } from "react";
import DashboardPage from "./dashboard";
import LoginPage from "./login.page";
import SingUpPage from "./singup.page";
import { Spin } from "antd";

function AppPage() {
  const [isOnline, setIsOnline] = useState<any>(null);
  useEffect(() => {
    setIsOnline(true);
    onMessageListener()
      .then((payload) => { })
      .catch((err) => { });
    return () => { setIsOnline(false) }
  }, [setIsOnline]);

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }

  return isOnline ? (
    <Fragment>
      <Router>
        <Switch>
          <Route
            exact={true}
            path="/singup"
            render={() => {
              return <SingUpPage />;
            }}
          ></Route>
          <Route
            exact={true}
            path="/login"
            render={() => {
              return <LoginPage />;
            }}
          ></Route>
          <Route
            // exact={true}
            path="/"
            render={() => {
              return <DashboardPage />;
            }}
          ></Route>
        </Switch>
      </Router>
    </Fragment>
  ) : (
    <Fragment>
      <h1>Hubo un error, consulte con TI</h1>
    </Fragment>
  );
}

export default AppPage;
