import { Fragment } from "react";
import { Solicitud } from "../../models/solicitud";
import { Table } from "antd";
import { Tag } from "antd";
import { Usuario } from "../../models/usuario";

function ListaSolicitudes({
  acciones,
  loadingTable,
  solicitudes,
  renderProductos,
  usuario,
}: {
  acciones: Function;
  loadingTable: boolean;
  solicitudes: Array<Solicitud>;
  renderProductos: Function;
  usuario: Usuario;
}) {
  const renderEstado = (value: any) => {
    if (value === "ABIERTA") {
      return (
        <Tag id={value} color="#4caf50">
          {value}
        </Tag>
      );
    } else {
      return (
        <Tag id={value} color="#f44336">
          {value}
        </Tag>
      );
    }
  };

  return (
    <Fragment>
      <Table
        pagination={{
          position: ["bottomCenter"],
          simple: false,
          size: "small",
        }}
        bordered
        loading={loadingTable}
        key="tablaSolicitudes"
        rowKey="id"
        columns={[
          {
            title: "Sede",
            dataIndex: "tienda",
            key: "tienda",
            align: "center",
            sorter: (a: any, b: any) => a.tienda.localeCompare(b.tienda),
            className: usuario.perfil === "Administrador" ? "hide" : "",
          },
          {
            title: "Estado",
            dataIndex: "estado",
            key: "estado",
            align: "center",
            filters: [
              {
                text: "ABIERTA",
                value: "ABIERTA",
              },
              {
                text: "CERRADA",
                value: "CERRADA",
              },
            ],
            onFilter: (value: any, record: any) =>
              record.estado?.indexOf(value) === 0,
            sorter: (a: any, b: any) => a.estado.localeCompare(b.estado),
            showSorterTooltip: false,
            render: (value, row, index) => {
              return renderEstado(value);
            },
          },
          {
            title: "Fecha de apertura",
            dataIndex: "fechaApertura",
            key: "fechaApertura",
            align: "center",
            sorter: (a: any, b: any) =>
              a.fechaApertura.localeCompare(b.fechaApertura),
          },
          {
            title: "Fecha de cierre",
            dataIndex: "fechaCierre",
            key: "fechaCierre",
            align: "center",
            sorter: (a: any, b: any) =>
              a.fechaCierre.localeCompare(b.fechaCierre),
          },
          {
            title: "Productos",
            dataIndex: "renderProductos",
            key: "renderProductos",
            align: "center",
          },
          {
            title: "Acciones",
            dataIndex: "acciones",
            key: "acciones",
            align: "center",
            className: usuario.perfil === "Ventas" ? "hide" : "",
          },
        ]}
        dataSource={solicitudes?.map((element: any) => ({
          ...element,
          renderProductos: renderProductos(element),
          acciones: acciones(element),
        }))}
      />
    </Fragment>
  );
}

export default ListaSolicitudes;
