import { DataBase } from "../database/database";
import { Producto } from "../models/producto";

export class APIProducto {
  async deleteProducto(producto: Producto): Promise<any> {
    const database = new DataBase();
    return await database.remove(
      `productos/${producto.sede}`,
      producto.id ?? ""
    );
  }

  async updateProducto(producto: Producto): Promise<any> {
    const database = new DataBase();
    return await database.set(
      `productos/${producto.sede}`,
      producto.id ?? "",
      producto.toMap()
    );
  }
}
