export interface UsuarioDataI {
  id?: string;
  nombre?: string;
  perfil?: string;
  sede?: string;
}

export class Usuario implements UsuarioDataI {
  id?: string;
  nombre?: string;
  perfil?: string;
  sede?: string;
  constructor(data: UsuarioDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.perfil = data.perfil;
    this.sede = data.sede;
  }
}
