import { Fragment } from "react";
import { useState } from "react";
import { Producto } from "../../models/producto";
import { Solicitud } from "../../models/solicitud";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Global from "../../global/global";

function SolicitudExcel({ solicitud }: { solicitud: Solicitud }) {
  const [fileName] = useState<String>(`REGISTRO M. F. ${solicitud.id}`);
  let currentdate: Date = new Date();
  let current: string = currentdate.toLocaleString("es-ES", {
    hour12: false,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  // current = current.replace(/\//g, "-");

  ReactHTMLTableToExcel.format = (s, c) => {
    if (c && c["table"]) {
      const html = c.table;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      c.table = doc.querySelector("table")!.outerHTML;
    }
    return s.replace(/{(\w+)}/g, (m, p) => c[p]);
  };

  return (
    <Fragment>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={fileName}
        sheet="REGISTRO M. F."
        buttonText="EXPORTAR A EXCEL"
      />
      <table id="table-to-xls" className="tableExcel">
        <thead>
          <tr>
            <th colSpan={2}>
              <img
                src="https://www.3aamseq.com.pe/rpf_productos/logoExcel.jpg"
                alt="3AAMSEQ"
                title="3AAMSEQ"
              />
            </th>
            <th colSpan={3}>REGISTRO DE MATERIAL FALLADO</th>
            <th>
              <table>
                <tbody>
                  <tr>
                    <td>CÓDIGO:</td>
                  </tr>
                  <tr>
                    <td>VERSIÓN:</td>
                  </tr>
                  <tr>
                    <td>PÁGINA:</td>
                  </tr>
                  <tr>
                    <td>CORRELATIVO: {Global.sedes.find(sede => sede['sede'] === solicitud.tienda)!['serie']} - {solicitud.correlativo}</td>
                  </tr>
                </tbody>
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={6}>
              <br />
            </td>
          </tr>
          <tr>
            <th colSpan={4} style={{ textAlign: "left", padding: "5px 5px" }}>
              ADMINISTRADOR(A) DE TIENDA: {solicitud.administradorTienda}
            </th>
            <th colSpan={2} style={{ textAlign: "left", padding: "5px 5px" }}>
              FECHA DE REPORTE: {current}
            </th>
          </tr>
          <tr>
            <th colSpan={4} style={{ textAlign: "left", padding: "5px 5px" }}>
              RESPONSABLE DE ALMACEN: {solicitud.responsableAlmacen}
            </th>
            <th colSpan={2} style={{ textAlign: "left", padding: "5px 5px" }}>
              TIENDA: {solicitud.tienda}
            </th>
          </tr>
          <tr>
            <th colSpan={4} style={{ textAlign: "left", padding: "5px 5px" }}>
              FECHA DE APERTURA: {solicitud.fechaApertura}
            </th>
            <th colSpan={2} style={{ textAlign: "left", padding: "5px 5px" }}>
              FECHA DE CIERRE: {solicitud.fechaCierre}
            </th>
          </tr>
          <tr>
            <td colSpan={6}>
              <br />
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              ITEM N°
            </th>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              CÓDIGO
            </th>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              DESCRIPCIÓN DEL PRODUCTO
            </th>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              CANTIDAD
            </th>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              TIPO DE FALLA
            </th>
            <th style={{ border: "1px solid black", textAlign: "center" }}>
              FECHA DE INGRESO
            </th>
          </tr>
          {solicitud.productos ? (
            solicitud.productos!.map((producto: Producto, index: number) => (
              <tr key={`tr-${index}`}>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {producto.codigo}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {producto.descripcion}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {producto.cantidad}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {producto.motivo}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {producto.fecha}
                </td>
              </tr>
            ))
          ) : (
            <br />
          )}
          <tr>
            <td colSpan={6}>
              <br />
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <br />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: "center" }}>
              _____________________________
              <br /> FIRMA DE RESPONSABLE DE LÍNEA
            </td>
            <td colSpan={2} style={{ textAlign: "center" }}>
              _____________________________
              <br /> RESPONSABLE DE ALMACÉN
            </td>
            <td colSpan={2} style={{ textAlign: "center" }}>
              _____________________________
              <br /> ADMINISTRADOR(A) DE TIENDA
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}

export default SolicitudExcel;
