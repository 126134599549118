import { APIProducto } from "../../api/api_producto";
import { APISolicitud } from "../../api/api_solicitud";
import { Button } from "antd";
import { Col } from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { DatePicker } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { getDatabase } from "@firebase/database";
import { Image } from "antd";
import { Input } from "antd";
import { Modal } from "antd";
import { onValue } from "@firebase/database";
import { Producto } from "../../models/producto";
import { ref } from "@firebase/database";
import { Row } from "antd";
import { Solicitud } from "../../models/solicitud";
import { Spin } from "antd";
import { Table } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useState } from "react";
import { Usuario } from "../../models/usuario";
import Global from "../../global/global";
import ProductoContext from "../../providers/productos-provider";
import SolicitudContext from "../../providers/solicitudes-provider";
import { Select } from "antd";
import moment from "moment";

function ListaProductos({
  acciones,
  productos,
  verFotos,
  renderMotivo,
  usuario,
  administradorTienda,
  origen,
  solicitud,
}: {
  acciones: Function;
  productos: Array<Producto>;
  verFotos: Function;
  renderMotivo: Function;
  usuario: Usuario;
  administradorTienda: String;
  origen: String;
  solicitud: Solicitud;
}) {
  const apiSolicitud = useContext<APISolicitud>(SolicitudContext);
  const apiProducto = useContext<APIProducto>(ProductoContext);
  const [isOnline, setIsOnline] = useState<any>(null);
  const [productosTemp, setProductosTemp] =
    useState<Array<Producto>>(productos);
  const [solicitudes, setSolicitudes] = useState<Array<Solicitud>>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [codigosSeleccionados] = useState<Array<any>>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [productosSeleccionados, setProductosSeleccionados] = useState<
    Array<Producto>
  >([]);
  const [spin, setSpin] = useState<any>(null);
  const [desde, setDesde] = useState<any>(null);
  const [hasta, setHasta] = useState<any>(null);
  const [filtrosFamilias, setFiltrosFamilias] = useState<Array<String>>([]);
  const [filtrosSubfamilias, setFiltrosSubfamilias] = useState<Array<String>>(
    []
  );
  const [filtrosMotivos, setFiltrosMotivos] = useState<Array<ColumnFilterItem>>(
    []
  );
  const history = useHistory();
  const { Option } = Select;

  const getSolicitudes = useCallback(async () => {
    const db = getDatabase();
    let dbRef: any = ref(db, `solicitudes/${usuario.sede}`);
    let _solicitud: Solicitud = new Solicitud({});
    let _solicitudes: Array<Solicitud> = [];
    onValue(dbRef, (snapshot: any) => {
      _solicitudes = [];
      if (snapshot) {
        snapshot.forEach((doc: any) => {
          const id = doc.ref._path.pieces_[2];
          _solicitud = new Solicitud(doc.val());
          _solicitud.id = id;
          _solicitudes.push(_solicitud);
        });
        setSolicitudes(_solicitudes);
      }
    });
    setIsOnline(true);
  }, [usuario.sede]);

  const onSelectChange = (codigosSeleccionados: Array<any>) => {
    if (codigosSeleccionados.length > 14) {
      codigosSeleccionados = codigosSeleccionados.splice(14);
    }
    let _productosSeleccionados: Array<Producto> = [];
    codigosSeleccionados.forEach((codigo: string, index: number) => {
      productos.forEach((producto: Producto, index: number) => {
        if (producto.id === codigo) {
          _productosSeleccionados.push(producto);
        }
      });
    });
    if (_productosSeleccionados.length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setProductosSeleccionados(_productosSeleccionados);
  };

  const rowSelection = {
    codigosSeleccionados,
    onChange: onSelectChange,
  };

  const generarSolicitud = async () => {
    if (solicitudes.length < 1) {
      Modal.confirm({
        title: "Confirmación",
        icon: <ExclamationCircleOutlined />,
        content: "¿Está seguro de registrar la solicitud?",
        okText: "Si, registrar",
        cancelText: "No, cancelar",
        onOk: async () => {
          registrarSolicitud();
        },
      });
    } else {
      var solicitudesAbiertas: Array<Solicitud> = [];
      solicitudesAbiertas = solicitudes.filter(
        (solicitud) => solicitud.estado === "ABIERTA"
      );

      if (solicitudesAbiertas.length > 1) {
        Modal.error({
          title: "3AAMSEQ",
          content: `Tienes ${solicitudesAbiertas.length} solicitudes abiertas, recuerda que sólo puedes tener una solicitud abierta a la vez.`,
        });
      } else if (solicitudesAbiertas.length === 1) {
        let solicitud: Solicitud = solicitudes.filter(
          (solicitud) => solicitud.estado === "ABIERTA"
        )[0];
        Modal.confirm({
          title: "Confirmación",
          icon: <ExclamationCircleOutlined />,
          content: "¿Está seguro de agregar estos productos?",
          okText: "Si, agregar",
          cancelText: "No, cancelar",
          onOk: async () => {
            actualizarSolicitud(solicitud);
          },
        });
      } else {
        Modal.confirm({
          title: "Confirmación",
          icon: <ExclamationCircleOutlined />,
          content: "¿Está seguro de registrar la solicitud?",
          okText: "Si, registrar",
          cancelText: "No, cancelar",
          onOk: async () => {
            registrarSolicitud();
          },
        });
      }
    }
  };

  const registrarSolicitud = async () => {
    setSpin(true);
    let currentdate: Date = new Date();
    let current: string = currentdate.toLocaleString("es-ES", {
      hour12: false,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    let correlativo: number = solicitudes.length + 1;
    let solicitud: Solicitud = new Solicitud({
      version: 1,
      administradorTienda: administradorTienda,
      responsableAlmacen: usuario.nombre,
      tienda: usuario.sede,
      productos: productosSeleccionados,
      estado: "ABIERTA",
      fechaApertura: current,
      correlativo: correlativo.toString(),
    });
    let result = await apiSolicitud.create(solicitud);
    if (result.success === true) {
      productosSeleccionados.forEach(async (producto, index) => {
        producto.suscrito = true;
        await apiProducto.updateProducto(producto);
      });
      setProductosSeleccionados([]);
      Modal.success({
        title: "3AAMSEQ",
        content: result.message,
        onOk: () => {
          history.replace("/solicitudes");
        },
      });
    } else {
      setSpin(false);
      Modal.error({
        title: "3AAMSEQ",
        content: result.message,
      });
    }
  };

  const actualizarSolicitud = async (solicitud: Solicitud) => {
    setSpin(true);
    let productosExistentes: Array<Producto> = solicitud.productos ?? [];
    productosSeleccionados.forEach(async (producto, index) => {
      productosExistentes.push(producto);
    });
    solicitud.productos = productosExistentes;
    let result = await apiSolicitud.updateSolicitud(solicitud);
    if (result.success === true) {
      productosSeleccionados.forEach(async (producto, index) => {
        producto.suscrito = true;
        await apiProducto.updateProducto(producto);
      });
      setProductosSeleccionados([]);
      Modal.success({
        title: "3AAMSEQ",
        content: result.message,
        onOk: () => {
          history.replace("/solicitudes");
        },
      });
    } else {
      setSpin(false);
      Modal.error({
        title: "3AAMSEQ",
        content: result.message,
      });
    }
  };

  const getFiltros = useCallback((productos: Array<Producto>) => {
    let _filtrosFamilias: Array<Object> = [];
    let _filtrosSubfamilias: Array<Object> = [];
    let _filtrosMotivos: Array<ColumnFilterItem> = [];
    setFiltrosFamilias([]);
    setFiltrosSubfamilias([]);
    setFiltrosMotivos([]);
    productos.forEach((producto: Producto, index: number) => {
      _filtrosFamilias.push(producto.familia!);
      _filtrosSubfamilias.push(producto.subfamilia!);
      _filtrosMotivos.push({
        text: producto.motivo,
        value: producto.motivo as string,
      });
    });
    setFiltrosFamilias(Global.listUnique(_filtrosFamilias));
    setFiltrosSubfamilias(Global.listUnique(_filtrosSubfamilias));
    setFiltrosMotivos(Global.arrayUnique(_filtrosMotivos, "value"));
    setIsOnline(true);
  }, []);

  const getProductos = useCallback(() => {
    setProductosTemp(productos);
    setLoadingTable(false);
    setIsOnline(true);
  }, [productos]);

  const searchProductoDescripcionOrCodigo = (producto: string) => {
    setLoadingTable(true);
    if (producto.length > 0) {
      let _productos: Array<Producto> = [];
      for (let i = 0; i < productos.length; i++) {
        let nombre: string = productos[i].descripcion?.toUpperCase() ?? "";
        let codigo: string = productos[i].codigo?.toUpperCase() ?? "";
        let consulta: string = producto.toUpperCase();
        if (nombre.indexOf(consulta) > -1 || codigo.indexOf(consulta) > -1) {
          _productos.push(productos[i]);
        }
      }
      setProductosTemp(_productos);
      getFiltros(_productos);
    } else {
      setProductosTemp(productos);
      getFiltros(productos);
    }
    setLoadingTable(false);
  };

  const handleReset = () => {
    getProductos();
  };

  const comprobarFecha = (desde: Date, hasta: Date, fecha: Date) => {
    return (
      fecha.getTime() >= desde.getTime() && fecha.getTime() <= hasta.getTime()
    );
  };

  const searchProductoFechas = () => {
    if (desde?.toString().length > 0 && hasta?.toString().length > 0) {
      setLoadingTable(true);
      let _desde: any = moment(desde)
        .format("DD/MM/YYYY")
        .toString()
        .split("/");
      let _hasta: any = moment(hasta)
        .format("DD/MM/YYYY")
        .toString()
        .split("/");
      _desde = new Date(`${_desde[2]}/${_desde[1]}/${_desde[0]}`);
      _hasta = new Date(`${_hasta[2]}/${_hasta[1]}/${_hasta[0]}`);
      let _productos: Array<Producto> = [];
      for (var i = 0; i < productos.length; i++) {
        let _fecha: Array<string> = productos[i]
          .fecha!.toString()
          .substr(0, 10)
          .split("/");
        let _registro = new Date(`${_fecha[2]}/${_fecha[1]}/${_fecha[0]}`);
        if (comprobarFecha(_desde, _hasta, _registro)) {
          _productos.push(productos[i]);
        }
      }
      setProductosTemp(_productos);
      getFiltros(_productos);
      setLoadingTable(false);
    } else {
      getProductos();
      getFiltros(productosTemp);
    }
  };

  const searchProductsFamily = (family: string) => {
    setLoadingTable(true);
    if (family === "0") {
      setProductosTemp(productos);
      getFiltros(productos);
    } else {
      let _productos: Array<Producto> = [];
      for (let i = 0; i < productos.length; i++) {
        let familia: string = productos[i].familia!;
        let consulta: string = family;
        if (familia.indexOf(consulta) > -1) {
          _productos.push(productos[i]);
        }
      }
      let _filtrosSubfamilias: Array<Object> = [];
      _productos.forEach((producto: Producto, index: number) => {
        _filtrosSubfamilias.push(producto.subfamilia!);
      });
      setFiltrosSubfamilias(Global.listUnique(_filtrosSubfamilias));
      setProductosTemp(_productos);
    }
    setLoadingTable(false);
  };

  const searchProductsSubfamily = (subfamily: string) => {
    setLoadingTable(true);
    if (subfamily === "0") {
      setProductosTemp(productos);
    } else {
      let _productos: Array<Producto> = [];
      for (let i = 0; i < productos.length; i++) {
        let subfamilia: string = productos[i].subfamilia!;
        let consulta: string = subfamily;
        if (subfamilia.indexOf(consulta) > -1) {
          _productos.push(productos[i]);
        }
      }
      setProductosTemp(_productos);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    getProductos();
    getSolicitudes();
    getFiltros(productos);
    return () => {
      setIsOnline(false);
    };
  }, [getProductos]);

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }

  return isOnline ? (
    <Fragment>
      <Spin spinning={spin}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="colSearchProductos"
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                xxl={8}
                className="colSearchProductosDescripcion"
              >
                <label>Buscar productos</label>
                <Input.Search
                  allowClear
                  onSearch={searchProductoDescripcionOrCodigo}
                  placeholder="Ingresa el nombre del producto"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={9}
                xl={9}
                xxl={9}
                className="colSearchProductosFecha"
              >
                <div style={{ padding: 8 }}>
                  <DatePicker
                    placeholder={"Desde"}
                    format="YYYY/MM/DD"
                    onChange={(e: any) =>
                      e !== null ? setDesde(e) : setDesde(null)
                    }
                  />
                  <DatePicker
                    placeholder={"Hasta"}
                    format="YYYY/MM/DD"
                    onChange={(e: any) =>
                      e !== null ? setHasta(e) : setHasta(null)
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => searchProductoFechas()}
                    size="small"
                    style={{ width: 80, margin: 5 }}
                  >
                    FILTRAR
                  </Button>
                  <Button
                    onClick={() => handleReset()}
                    size="small"
                    type="primary"
                    style={{ width: 80, margin: 5 }}
                  >
                    TODOS
                  </Button>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={7}
                xl={7}
                xxl={7}
                className="colSearchProductosFecha"
              >
                <div style={{ padding: 8, width: "100%" }}>
                  <Select
                    placeholder="Elija una opción"
                    style={{ width: "48%", margin: "5px 1%" }}
                    onChange={searchProductsFamily}
                  >
                    <Option value="0">Escoge una opci&oacute;n</Option>
                    {filtrosFamilias.map((familia: String, index: number) => (
                      <Option
                        key={`${familia}_${index}`}
                        value={familia as string}
                      >
                        {familia}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Elija una opción"
                    style={{ width: "48%", margin: "5px 1%" }}
                    onChange={searchProductsSubfamily}
                  >
                    <Option value="0">Escoge una opci&oacute;n</Option>
                    {filtrosSubfamilias.map(
                      (subfamilia: String, index: number) => (
                        <Option
                          key={`${subfamilia}_${index}`}
                          value={subfamilia as string}
                        >
                          {subfamilia}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ display: visible ? "block" : "none" }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="colButtonGenerarSolicitud"
          >
            <Button type="primary" onClick={generarSolicitud}>
              {/* {solicitudes.length > 0
                ? "AGREGAR A SOLICITUD ABIERTA"
                : "CREAR NUEVA SOLICITUD"} */}
              GENERAR SOLICITUD
            </Button>
            <br />
            Productos seleccionados: {productosSeleccionados.length}
          </Col>
        </Row>
        <Table
          pagination={{
            position: ["bottomCenter"],
            simple: false,
            size: "small",
            pageSize: 14,
          }}
          bordered
          loading={loadingTable}
          key="tablaProductos"
          rowKey="id"
          rowSelection={origen === "PRODUCTOS" ? rowSelection : undefined}
          columns={[
            {
              title: "Galería",
              dataIndex: "fotos",
              key: "fotos",
              align: "center",
              render: (fotos: Array<String>) => (
                <Image
                  onClick={fotos ? () => verFotos(fotos) : () => {}}
                  preview={false}
                  width={100}
                  height={100}
                  src={fotos ? (fotos[0] as string) : ""}
                  fallback={Global.IMAGE_ERROR}
                  style={{ cursor: "pointer", borderRadius: "10px" }}
                  loading="lazy"
                />
              ),
            },
            {
              title: "Código",
              key: "codigo",
              dataIndex: "codigo",
              align: "center",
              sorter: (a: any, b: any) => a.codigo.localeCompare(b.codigo),
            },
            {
              title: "Familia",
              dataIndex: "familia",
              key: "familia",
              align: "center",
              // filters: filtrosFamilias,
              // onFilter: (value: any, producto: Producto) =>
              //   producto.familia?.indexOf(value) === 0,
              // onFilter: (value: any, producto: Producto) =>
              //   onFilterTable(value, producto),
              sorter: (a: any, b: any) => a.familia.localeCompare(b.familia),
            },
            {
              title: "Sufamilia",
              dataIndex: "subfamilia",
              key: "subfamilia",
              align: "center",
              // filters: filterData(productosTemp)(i => i.subfamilia),
              // OBETENEMOS LOS FILTROS DE ACUERDO A COMO VAN LLEGANDO LOS PRODUCTOS
              // onFilter: (value: any, record: Producto) =>
              //   record.subfamilia?.indexOf(value) === 0,
              sorter: (a: any, b: any) =>
                a.subfamilia.localeCompare(b.subfamilia),
            },
            {
              title: "Producto",
              key: "descripcion",
              dataIndex: "descripcion",
              align: "center",
              sorter: (a: any, b: any) =>
                a.descripcion.localeCompare(b.descripcion),
            },
            {
              title: "Cantidad",
              dataIndex: "cantidad",
              key: "cantidad",
              align: "center",
              sorter: (a: any, b: any) => a.cantidad - b.cantidad,
            },
            {
              title: "Motivo",
              dataIndex: "renderMotivo",
              key: "renderMotivo",
              align: "center",
              filters: filtrosMotivos,
              onFilter: (value: any, record: Producto) =>
                record.motivo?.indexOf(value) === 0,
              sorter: (a: any, b: any) => a.motivo.localeCompare(b.motivo),
            },
            {
              title: "Usuario",
              dataIndex: "creador",
              key: "creador",
              align: "center",
              sorter: (a: any, b: any) => a.creador.localeCompare(b.creador),
            },
            {
              title: "Fecha",
              dataIndex: "fecha",
              key: "fecha",
              align: "center",
              sorter: (a: any, b: any) => a.fecha.localeCompare(b.fecha),
            },
            {
              title: "Acciones",
              dataIndex: "acciones",
              key: "acciones",
              align: "center",
              className:
                usuario.perfil === "Ventas"
                  ? "hide"
                  : origen === "SOLICITUDES"
                  ? solicitud.estado === "ABIERTA"
                    ? ""
                    : "hide"
                  : "",
            },
          ]}
          dataSource={productosTemp?.map((element: any) => ({
            ...element,
            renderMotivo: renderMotivo(element),
            acciones: acciones(element),
          }))}
        />
      </Spin>
    </Fragment>
  ) : (
    <Fragment>
      <h1>Hubo un error, consulte con TI</h1>
    </Fragment>
  );
}

export default ListaProductos;
