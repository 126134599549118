import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { Modal } from "antd";

const firebaseConfig = {
  apiKey: "AIzaSyCux0BkHy_Pwgft1esbcqV1z_FmdB3SGds",
  authDomain: "registro-de-fallados.firebaseapp.com",
  databaseURL: "https://registro-de-fallados-default-rtdb.firebaseio.com",
  projectId: "registro-de-fallados",
  storageBucket: "registro-de-fallados.appspot.com",
  messagingSenderId: "598013068094",
  appId: "1:598013068094:web:66fa6a5fd594146168e26f",
  measurementId: "G-ZRNYXKRC40",
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = async () => {
  let token = "";
  await messaging
    .getToken({
      vapidKey:
        "BBPKT-_-FkYU8meMysq6HpZl6TYOkVaG0IH7Urt686dUzYKDd5swEU4HeAfuSr2mZhNeSnmpXiDmfGTXEAjoI5Y",
    })
    .then((currentToken) => {
      token = currentToken;
      if (currentToken) {
        token = currentToken;
      } else {
      }
    })
    .catch((err) => {});
  return token;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      new Audio("/notification.mp3").play();
      Modal.info({
        title: payload.notification.title,
        content: payload.notification.body,
      });
      resolve(payload);
    });
  });
