import "../../styles/pages/login.page.css";
import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { Fragment, useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../models/usuario";
import logoAzul from "../../assets/logoAzul.svg";
import UsuarioContext from "../../providers/usuarios-provider";

function LoginPage() {
  const [isOnline, setIsOnline] = useState<any>(null);
  const apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const [spin, setSpin] = useState<boolean>(false);
  const { Title } = Typography;
  const history = useHistory();

  const getCurrentUser = useCallback(async () => {
    let result = await apiUsuario.getCurrentUser();
    if (result) {
      let user: Usuario = new Usuario(result);
      if (user.id) {
        history.replace("/");
      }
    }
  }, [apiUsuario, history]);

  useEffect(() => {
    setIsOnline(true);
    getCurrentUser();
    return () => { setIsOnline(false) }
  }, [apiUsuario, history, getCurrentUser]);

  const login = async (values: any) => {
    setSpin(true);
    let result = await apiUsuario.login(values["correo"], values["password"]);
    if (result.success === true) {
      Modal.success({
        title: "3AAMSEQ",
        content: "Bienvenido " + result.data.nombre,
        onOk: () => {
          history.push("/");
        },
      });
    }
    if (result.success === false) {
      setSpin(false);
      Modal.error({
        title: "3AAMSEQ",
        content: result.data.message,
      });
    }
  };

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }

  return isOnline ? (
    <div className="centerFlex containerPadre">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <img
            src={logoAzul}
            className="logoLogin"
            alt="3AAMSEQ"
            title="3AAMSEQ"
            loading="lazy"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={2} className="azulC title">
            REGISTRO DE MATERIAL FALLADO
          </Title>
          <Title level={5} className="azulC title">
            Ingresa los datos solicitados para iniciar sesi&oacute;n
          </Title>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form
            name="formLogin"
            initialValues={{
              remember: true,
            }}
            onFinish={login}
            // autoComplete="off"
            className="formLogin"
            layout="vertical"
          >
            <Form.Item
              label="Correo"
              name="correo"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu correo correo!",
                  type: "email",
                },
              ]}
              help={<br />}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu contraseña!",
                },
              ]}
              help={<br />}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              {spin ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Iniciar sesi&oacute;n
                </Button>
              )}
            </Form.Item>
            {/* <Form.Item>
              <a href="/singup" className="azulC">
                Reg&iacute;strate
              </a>
            </Form.Item> */}
          </Form>
        </Col>
      </Row>
    </div>
  ) : (
    <Fragment>
      <h1>Hubo un error, consulte con TI</h1>
    </Fragment>
  );
}

export default LoginPage;
