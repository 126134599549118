import "../../styles/pages/singup.page.css";
import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Select } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { useContext } from "react";
import { useState } from "react";
import logoAzul from "../../assets/logoAzul.svg";
import UsuarioContext from "../../providers/usuarios-provider";

function SingUpPage() {
  const apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const [spin, setSpin] = useState<boolean>(false);
  const { Title } = Typography;
  const { Option } = Select;

  const createUser = async (values: any) => {
    setSpin(true);
    saveUser(
      values["correo"],
      values["password"],
      values["nombre"],
      values["perfil"],
      values["sede"]
    );
  };

  const saveUser = async (
    correo: string,
    password: string,
    nombre: string,
    perfil: string,
    sede: string
  ) => {
    let result = await apiUsuario.create(
      correo,
      password,
      nombre,
      perfil,
      sede
    );
    if (result.success === true) {
      Modal.success({
        title: "3AAMSEQ",
        content: "Usuario registrado",
      });
    } else {
      Modal.error({
        title: "3AAMSEQ",
        content: result.data.message,
      });
    }
    setSpin(false);
  };

  return (
    <div className="centerFlex containerPadre">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <img
            src={logoAzul}
            className="logoSingup"
            alt="3AAMSEQ"
            title="3AAMSEQ"
            loading="lazy"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={2} className="azulC title">
            Reg&iacute;strate
          </Title>
          <Title level={5} className="azulC title">
            Ingresa los datos solicitados para registrarte
          </Title>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form
            name="formSingup"
            initialValues={{
              remember: true,
            }}
            onFinish={createUser}
            autoComplete="off"
            className="formSingup"
            layout="vertical"
          >
            <Form.Item
              label="Perfil"
              name="perfil"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona tu perfil!",
                },
              ]}
            >
              <Select placeholder="Elija una opción">
                <Option value="Ventas">Ventas</Option>
                <Option value="Almacenero">Almacenero</Option>
                <Option value="Administrador">Administrador</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Nombre completo"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu nombre!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Correo"
              name="correo"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu correo correo!",
                  type: "email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa tu contraseña!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Sede"
              name="sede"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona tu sede!",
                },
              ]}
            >
              <Select placeholder="Elija una opción">
                <Option value="AREQUIPA">AREQUIPA</Option>
                <Option value="CHICLAYO I">CHICLAYO I</Option>
                <Option value="CHICLAYO II">CHICLAYO II</Option>
                <Option value="CHIMBOTE">CHIMBOTE</Option>
                <Option value="HUANCAYO">HUANCAYO</Option>
                <Option value="LIMA PUENTE PIEDRA">LIMA PUENTE PIEDRA</Option>
                <Option value="LIMA SAN JUAN DE LURIGANCHO">
                  LIMA SAN JUAN DE LURIGANCHO
                </Option>
                <Option value="LIMA VILLA EL SALVADOR">
                  LIMA VILLA EL SALVADOR
                </Option>
                <Option value="PIURA I">PIURA I</Option>
                <Option value="PIURA II">PIURA II</Option>
                <Option value="TARAPOTO I">TARAPOTO I</Option>
                <Option value="TARAPOTO II">TARAPOTO II</Option>
                <Option value="TRUJILLO">TRUJILLO</Option>
                <Option value="OFICINA TRUJILLO">OFICINA TRUJILLO</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              {spin ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Registrar
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <a href="/login" className="azulC">
                Iniciar sesi&oacute;n
              </a>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SingUpPage;
