import { DataBase } from "../database/database";
import { Solicitud } from "../models/solicitud";

export class APISolicitud {
  async create(solicitud: Solicitud): Promise<any> {
    const database = new DataBase();
    let result: any = await database.push(
      "solicitudes/",
      solicitud.tienda ?? "",
      solicitud.toMap()
    );
    if (result.success === true) {
      const doc: any = result.data;
      const id = doc.ref._path.pieces_[2];
      solicitud.id = id;
      this.updateSolicitud(solicitud);
    }
    return result;
  }

  async updateSolicitud(solicitud: Solicitud): Promise<any> {
    const database = new DataBase();
    let result: any = await database.set(
      `solicitudes/${solicitud.tienda}`,
      solicitud.id ?? "",
      solicitud.toMap()
    );
    return result;
  }

  async deleteSolicitud(solicitud: Solicitud): Promise<any> {
    const database = new DataBase();
    return await database.remove(
      `solicitudes/${solicitud.tienda}`,
      solicitud.id ?? ""
    );
  }
}
