import { child, DataSnapshot, get, getDatabase, ref } from "@firebase/database";
import { Modal } from "antd";
import { Usuario } from "../models/usuario";

export class Services {
  async getAdministrador(usuario: Usuario): Promise<String> {
    const db = getDatabase();
    let dbRef: any = ref(db);
    let administradorTienda: String = "";
    await get(child(dbRef, `tiendas/${usuario.sede?.toLocaleUpperCase()}`))
      .then(async (snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((tienda: any) => {
            administradorTienda = tienda.val();
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "3AAMSEQ",
          content: error,
        });
      });
    return administradorTienda;
  }
}
