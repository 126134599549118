export interface ProductoDataI {
  id?: string;
  codigo?: string;
  familia?: string;
  subfamilia?: string;
  descripcion?: string;
  cantidad?: string;
  motivo?: string;
  submotivo?: string;
  fotos?: Array<string>;
  creador?: string;
  sede?: string;
  fecha?: string;
  suscrito?: boolean;
}

export class Producto implements ProductoDataI {
  id?: string;
  codigo?: string;
  familia?: string;
  subfamilia?: string;
  descripcion?: string;
  cantidad?: string;
  motivo?: string;
  submotivo?: string;
  fotos?: Array<string>;
  creador?: string;
  sede?: string;
  fecha?: string;
  suscrito?: boolean;
  constructor(data: ProductoDataI) {
    this.id = data.id;
    this.codigo = data.codigo;
    this.familia = data.familia;
    this.subfamilia = data.subfamilia;
    this.descripcion = data.descripcion;
    this.cantidad = data.cantidad;
    this.motivo = data.motivo;
    this.submotivo = data.submotivo;
    this.fotos = data.fotos;
    this.creador = data.creador;
    this.sede = data.sede;
    this.fecha = data.fecha;
    this.suscrito = data.suscrito;
  }

  toMap() {
    return {
      id: this.id ?? "",
      codigo: this.codigo ?? "",
      familia: this.familia ?? "",
      subfamilia: this.subfamilia ?? "",
      descripcion: this.descripcion ?? "",
      cantidad: this.cantidad ?? "",
      motivo: this.motivo ?? "",
      submotivo: this.submotivo ?? "",
      fotos: this.fotos ?? [],
      creador: this.creador ?? "",
      sede: this.sede ?? "",
      fecha: this.fecha ?? "",
      suscrito: this.suscrito ?? false,
    };
  }
}
