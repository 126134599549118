import { getDatabase, ref, set, push } from "firebase/database";

export class DataBase {
  push = async (table: String, id: String, data: {}) => {
    const database = getDatabase();
    let result: {} = {};
    await push(ref(database, `${table}/${id}`), data)
      .then((snapshot: any) => {
        result = {
          success: true,
          message: "Solicitud registrada",
          data: snapshot,
        };
      })
      .catch((error: any) => {
        result = {
          success: false,
          message: error,
        };
      });
    return result;
  };

  set = async (table: String, id: String, data: {}) => {
    const database = getDatabase();
    let result: {} = {};
    await set(ref(database, `${table}/${id}`), data)
      .then(() => {
        result = {
          success: true,
          message: "Solicitud actualizada",
        };
      })
      .catch((error) => {
        result = {
          success: false,
          message: error,
        };
      });

    return result;
  };

  remove = async (table: String, id: String) => {
    const database = getDatabase();
    let result: any = set(ref(database, `${table}/${id}`), null);
    return result;
  };
}
