import "../../../styles/pages/dashboard/solicitudes.css";
import { APISolicitud } from "../../../api/api_solicitud";
import { Button, Spin } from "antd";
import { CheckOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Col } from "antd";
import { DatePicker } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileExcelFilled } from "@ant-design/icons";
import { Fragment } from "react";
import { getDatabase } from "@firebase/database";
import { Modal } from "antd";
import { onValue } from "@firebase/database";
import { PlusOutlined } from "@ant-design/icons";
import { Producto } from "../../../models/producto";
import { ref } from "@firebase/database";
import { Row } from "antd";
import { Solicitud } from "../../../models/solicitud";
import { Space } from "antd";
import { TabletFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useState } from "react";
import { Usuario } from "../../../models/usuario";
import ListaProductos from "../../components/lista_productos";
import ListaSolicitudes from "../../components/lista_solicitudes";
import SolicitudContext from "../../../providers/solicitudes-provider";
import VerFotos from "../../components/ver_fotos";
import SolicitudExcel from "../../components/solicitud_excel";
import { APIProducto } from "../../../api/api_producto";
import ProductoContext from "../../../providers/productos-provider";
import moment from "moment";

function SolicitudesPage({ usuario }: { usuario: Usuario }) {
  const [isOnline, setIsOnline] = useState<any>(null);
  const apiSolicitud = useContext<APISolicitud>(SolicitudContext);
  const apiProducto = useContext<APIProducto>(ProductoContext);
  const [solicitudes, setSolicitudes] = useState<Array<Solicitud>>([]);
  const [solicitudSelected, setSolicitudSelected] = useState<Solicitud>(
    new Solicitud({})
  );
  const [solicitudesTemp, setSolicitudesTemp] = useState<Array<Solicitud>>([]);
  const [productos, setProductos] = useState<Array<Producto>>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [visibleProductos, setVisibleProductos] = useState<boolean>(false);
  const [visibleReporteExcel, setVisibleReportevisibleReporteExcel] =
    useState<boolean>(false);
  const [desde, setDesde] = useState<any>(null);
  const [hasta, setHasta] = useState<any>(null);
  const [imagesProductos, setImagesProductos] = useState<Array<String>>([]);
  const [visibleFotos, setVisibleFotos] = useState<boolean>(false);

  const { Title } = Typography;
  const history = useHistory();

  const getSolicitudes = useCallback(async () => {
    setSolicitudes([]);
    setSolicitudesTemp([]);
    const db = getDatabase();
    let dbRef: any = ref(db, `solicitudes/${usuario.sede}`);
    let _solicitudes: Array<Solicitud> = [];
    let solicitud: Solicitud = new Solicitud({});
    onValue(dbRef, (snapshot: any) => {
      _solicitudes = [];
      snapshot.forEach((doc: any) => {
        let id: string = doc.ref._path.pieces_[2];
        solicitud = new Solicitud(doc.val());
        solicitud.id = id;
        _solicitudes.push(solicitud);
      });

      setSolicitudes(
        _solicitudes.sort(
          (a: Solicitud, b: Solicitud) =>
            b.fechaApertura?.localeCompare(a.fechaApertura as string) ?? 0
        )
      );
      setSolicitudesTemp(
        _solicitudes.sort(
          (a: Solicitud, b: Solicitud) =>
            b.fechaApertura?.localeCompare(a.fechaApertura as string) ?? 0
        )
      );
    });
    setLoadingTable(false);
    setIsOnline(true);
  }, [usuario]);

  const comprobarFecha = (desde: Date, hasta: Date, fecha: Date) => {
    console.log({
      desde,
      hasta,
      fecha,
    });
    return (
      fecha.getTime() >= desde.getTime() && fecha.getTime() <= hasta.getTime()
    );
  };

  const searchSolicitudesFechas = () => {
    if (desde!.toString().length > 0 && hasta!.toString().length > 0) {
      setLoadingTable(true);
      let _desde: any = moment(desde)
        .format("DD/MM/YYYY")
        .toString()
        .split("/");
      let _hasta: any = moment(hasta)
        .format("DD/MM/YYYY")
        .toString()
        .split("/");
      _desde = new Date(`${_desde[2]}/${_desde[1]}/${_desde[0]}`);
      _hasta = new Date(`${_hasta[2]}/${_hasta[1]}/${_hasta[0]}`);
      let _solicitudes: Array<Solicitud> = [];
      for (var i = 0; i < solicitudesTemp.length; i++) {
        let _fechaA: Array<string> = solicitudesTemp[i]
          .fechaApertura!.toString()
          .substr(0, 10)
          .split("/");
        let _fechaB: Array<string> = solicitudesTemp[i]
          .fechaCierre!.toString()
          .substr(0, 10)
          .split("/");
        let _fechaApertura = new Date(
          `${_fechaA[2]}/${_fechaA[1]}/${_fechaA[0]}`
        );
        let _fechaCierre = new Date(
          `${_fechaB[2]}/${_fechaB[1]}/${_fechaB[0]}`
        );
        if (
          comprobarFecha(_desde, _hasta, _fechaApertura) ||
          comprobarFecha(_desde, _hasta, _fechaCierre)
        ) {
          _solicitudes.push(solicitudesTemp[i]);
        }
      }
      setSolicitudes(_solicitudes);
      setLoadingTable(false);
    } else {
      getSolicitudes();
    }
  };

  const handleReset = () => {
    getSolicitudes();
  };

  const verProductos = (solicitud: Solicitud) => {
    setSolicitudSelected(solicitud);
    setProductos(solicitud.productos ?? []);
    setVisibleProductos(true);
  };

  const onCancelProductos = () => {
    setProductos([]);
    setVisibleProductos(false);
  };

  const onCancelReporteExcel = () => {
    setSolicitudSelected(new Solicitud({}));
    setVisibleReportevisibleReporteExcel(false);
  };

  const renderProductos = (solicitud: Solicitud) => {
    return (
      <Space key={solicitud.id?.toString()}>
        <Tooltip title="Ver productos">
          <TabletFilled
            onClick={() => verProductos(solicitud)}
            style={{
              cursor: "pointer",
              fontSize: "x-large",
              color: "rgba(0, 150, 136, 1)",
            }}
          />
        </Tooltip>
      </Space>
    );
  };

  const acciones = (solicitud: Solicitud) => {
    if (solicitud.estado === "ABIERTA") {
      return (
        <Space key={solicitud.id?.toString()}>
          <Tooltip title="Agregar productos">
            <PlusOutlined
              style={{ color: "#222f4b", fontSize: "x-large" }}
              onClick={() => {
                history.replace("/productos");
              }}
            />
          </Tooltip>
          <Tooltip title="Cerrar">
            <CheckOutlined
              style={{ color: "#607d8b", fontSize: "x-large" }}
              onClick={() => {
                cerrarSolicitud(solicitud);
              }}
            />
          </Tooltip>
        </Space>
      );
    } else {
      return (
        <Space key={solicitud.id?.toString()}>
          <Tooltip title="Generar reporte">
            <FileExcelFilled
              style={{ color: "#4caf50", fontSize: "x-large" }}
              onClick={() => {
                generarReporteExcel(solicitud);
              }}
            />
          </Tooltip>
        </Space>
      );
    }
  };

  const accionesProductosSolicitud = (producto: Producto) => {
    return (
      <Space key={producto.id?.toString()}>
        <Tooltip title="Eliminar">
          <CloseCircleFilled
            style={{ color: "#f44336", fontSize: "x-large" }}
            onClick={() => eliminarProductoSolicitud(producto)}
          />
        </Tooltip>
      </Space>
    );
  };

  const eliminarProductoSolicitud = async (producto: Producto) => {
    Modal.confirm({
      title: "Confirmación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Está seguro de quitar este producto de la solicitud?",
      okText: "Si, quitar",
      cancelText: "No, cancelar",
      onOk: () => {
        _eliminarProductoSolicitud(producto);
      },
    });
  };

  const _eliminarProductoSolicitud = async (producto: Producto) => {
    let _producto: Producto = new Producto({
      id: producto.id,
      codigo: producto.codigo,
      familia: producto.familia,
      subfamilia: producto.subfamilia,
      descripcion: producto.descripcion,
      cantidad: producto.cantidad,
      motivo: producto.motivo,
      submotivo: producto.submotivo,
      fotos: producto.fotos,
      creador: producto.creador,
      sede: producto.sede,
      fecha: producto.fecha,
      suscrito: false,
    });
    let productosActuales: Array<Producto> =
      solicitudSelected.productos?.filter(
        (producto: Producto, index: number) => producto.id !== _producto.id
      ) ?? [];
    solicitudSelected.productos = productosActuales;
    let result = await apiSolicitud.updateSolicitud(solicitudSelected);
    if (result.success === true) {
      setSolicitudSelected(solicitudSelected);
      setProductos(productosActuales);
      await apiProducto
        .updateProducto(_producto)
        .then((success) =>
          Modal.success({
            title: "3AAMSEQ",
            content: "Producto eliminado de la solicitud",
          })
        )
        .catch((error) =>
          Modal.error({
            title: "3AAMSEQ",
            content: "Error al eliminar producto: " + error,
          })
        );
    } else {
      Modal.error({
        title: "3AAMSEQ",
        content: result.message,
      });
    }
  };

  const generarReporteExcel = (solicitud: Solicitud) => {
    setSolicitudSelected(solicitud);
    setVisibleReportevisibleReporteExcel(true);
  };

  const cerrarSolicitud = (solicitud: Solicitud) => {
    if (solicitud.productos) {
      Modal.confirm({
        title: "Confirmación",
        icon: <ExclamationCircleOutlined />,
        content:
          "¿Está seguro de cerrar la solicitud? Esta acción no se puede deshacer",
        okText: "Si, cerrar",
        cancelText: "No, cancelar",
        onOk: async () => {
          _cerrarSolicitud(solicitud);
        },
      });
    } else {
      Modal.warning({
        title: "3AAMSEQ",
        content:
          "Para cerrar la solicitud debes tener al menos un producto registrado",
      });
    }
  };

  const _cerrarSolicitud = async (solicitud: Solicitud) => {
    let currentdate: Date = new Date();
    let current: string = currentdate.toLocaleString("es-ES", {
      hour12: false,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    // current = current.replace(/\//g, "-");
    solicitud.estado = "CERRADA";
    solicitud.fechaCierre = current;
    let result = await apiSolicitud.updateSolicitud(solicitud);
    if (result.success === true) {
      Modal.success({
        title: "3AAMSEQ",
        content: result.message,
      });
    } else {
      Modal.error({
        title: "3AAMSEQ",
        content: result.message,
      });
    }
  };

  const verFotos = (images: Array<String>) => {
    setImagesProductos(images);
    setVisibleFotos(true);
  };

  const onCancelFotos = () => {
    setImagesProductos([]);
    setVisibleFotos(false);
  };

  const renderMotivo = (producto: Producto) => {
    if (producto.submotivo) {
      return producto.motivo + ": " + producto.submotivo;
    } else {
      return producto.motivo;
    }
  };

  useEffect(() => {
    getSolicitudes();
    return () => {
      setIsOnline(false);
    };
  }, []);

  if (isOnline === null) {
    return (
      <Fragment>
        <Spin />
      </Fragment>
    );
  }
  return isOnline ? (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={2} className="azulC tituloSolicitud">
            Lista de solicitudes de material fallado
          </Title>
          <Title level={5} className="azulC subtituloSolicitud">
            A continuación se muestra el listado de las solicitudes de material
            fallado
          </Title>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="colSearchSolicitudesFecha"
        >
          <div style={{ padding: 8 }}>
            <DatePicker
              placeholder={"Desde"}
              format="YYYY/MM/DD"
              onChange={(e: any) => (e !== null ? setDesde(e) : setDesde(null))}
            />
            <DatePicker
              placeholder={"Hasta"}
              format="YYYY/MM/DD"
              onChange={(e: any) => (e !== null ? setHasta(e) : setHasta(null))}
            />
            <Button
              type="primary"
              onClick={() => searchSolicitudesFechas()}
              size="small"
              style={{ width: 90, margin: 5 }}
            >
              FILTRAR
            </Button>
            <Button
              onClick={() => handleReset()}
              size="small"
              type="primary"
              style={{ width: 90, margin: 5 }}
            >
              TODOS
            </Button>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="colTablaProductos"
        >
          <ListaSolicitudes
            acciones={acciones}
            loadingTable={loadingTable}
            solicitudes={solicitudes}
            renderProductos={renderProductos}
            usuario={usuario}
          />
        </Col>
      </Row>
      {/* Modal para ver los productos de la solicitud */}
      <Modal
        maskClosable={false}
        style={{ textAlign: "center" }}
        title="Productos registrados en esta solicitud"
        visible={visibleProductos}
        onCancel={onCancelProductos}
        footer={null}
        width={1500}
      >
        <ListaProductos
          acciones={accionesProductosSolicitud}
          productos={productos}
          verFotos={verFotos}
          renderMotivo={renderMotivo}
          usuario={usuario}
          administradorTienda={""}
          origen="SOLICITUDES"
          solicitud={solicitudSelected}
        />
      </Modal>
      {/* Modal para ver las fotos de los productos */}
      <Modal
        maskClosable={false}
        style={{ textAlign: "center" }}
        title="Fotos de los productos"
        visible={visibleFotos}
        onCancel={onCancelFotos}
        footer={null}
      >
        <VerFotos imagesProductos={imagesProductos} />
      </Modal>
      {/* Modal para exportar a excel */}
      <Modal
        maskClosable={false}
        style={{ textAlign: "center" }}
        title="FORMATO DE EXPORTACIÓN A EXCEL"
        visible={visibleReporteExcel}
        onCancel={onCancelReporteExcel}
        footer={null}
        width={1200}
      >
        <SolicitudExcel solicitud={solicitudSelected} />
      </Modal>
    </Fragment>
  ) : (
    <Fragment>
      <h1>Hubo un error, consulte con TI</h1>
    </Fragment>
  );
}

export default SolicitudesPage;
